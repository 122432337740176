import React from 'react';
import logo from './dcc-logo.svg';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { BrowserRouter as Router, Route, Link, Redirect, useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './App.css';
import EditApplication from './EditApplication.js';
import ListApplications from './ListApplications.js';
import Reports from './Reports.js';
import Login from './Auth/Login.js';
import Logout from './Auth/Logout.js';
import ChangePassword from './Auth/ChangePassword.js';
import auth from './Auth/Auth.js';
import EligibilityCheckerNavItem from './EligibilityCheckerNavItem.js';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    auth.isAuthenticated()
      ? <Component {...props} />
      : <Redirect to={{
          pathname: "/login",
          state: {
            prevLocation: props.location,
          },
        }}/>
  )} />
)

const EditApplicationRoute = ({ match }) => (<EditApplication id={match.params.id}/>);

function Menu() {
  useHistory(); // this will force update on route change (important after login/logout)

  return (
    <Nav className="mr-auto" navbar>
      {auth.isAuthenticated() &&
        <React.Fragment>
          <NavItem>
            <NavLink tag={Link} to="/editApplication">New Application</NavLink>
          </NavItem>
          {auth.hasPermission("check_eligibility") &&
            <NavItem>
              <EligibilityCheckerNavItem/>
            </NavItem>
          }
          {(auth.isAdmin() || auth.hasPermission("agency_report")) &&
            <NavItem>
              <NavLink tag={Link} to="/reports">Reports</NavLink>
            </NavItem>
          }
          {auth.isAdmin() &&
            <NavItem>
              <NavLink href={`${process.env.REACT_APP_BACKEND_URL}/admin`} target="_blank">Admin</NavLink>
            </NavItem>
          }
        </React.Fragment>
      }
      {auth.isAuthenticated() ?
        <React.Fragment>
          <NavItem>
            <NavLink tag={Link} to="/changePassword">Change Password</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/logout">Logout</NavLink>
          </NavItem>
        </React.Fragment>
      :
        <NavItem>
          <NavLink tag={Link} to="/login">Login</NavLink>
        </NavItem>
      }
    </Nav>
  );
}

function App() {

  return (
    <Router>
      <div className="App">
        <Navbar color="light" light expand="md">
          <NavbarBrand tag={Link} to="/">
            <img src={logo} width="180" height="60" className="d-inline-block align-top" alt="Home"/>
          </NavbarBrand>
          <Menu/>
        </Navbar>

        <ToastContainer newestOnTop/>

        <div className="content">
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <PrivateRoute exact path="/" component={ListApplications}/>
          <PrivateRoute exact path="/editApplication/:id" component={EditApplicationRoute}/>
          <PrivateRoute exact path="/editApplication" component={EditApplication}/>
          <PrivateRoute exact path="/reports" component={Reports}/>
          <PrivateRoute exact path="/changePassword" component={ChangePassword}/>
        </div>
      </div>

    </Router>
  );
}

export default App;
