import React, { Component } from 'react';
import { Button, Col, Row, Form } from 'reactstrap';

import auth from './Auth.js';
import { handleInputChange, FormInput, reactStateToDoc } from '../Form.js';
import {ValidationException} from '../API.js';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      _validation: {}
    };
    this.handleInputChange = handleInputChange.bind(this);
    this.FormInput = FormInput.bind(this);
    this.reactStateToDoc = reactStateToDoc.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({_validation: {}});
    var history = this.props.history;
    var loginData = this.reactStateToDoc(this.state);
    try {
      await auth.login(loginData);
      const prevLocation = this.props.location.state && this.props.location.state.prevLocation;
      history.replace(prevLocation || "/");
    }
    catch(err) {
      console.log(err);
      if (err instanceof ValidationException) {
        this.setState({_validation: {username: "Invalid credentials."}});
      }
      else {
        this.setState({_validation: {username: "Cannot login: " + err}});
      }
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <Row>
          <this.FormInput name="username" label="User Name" sm={6} md={4} lg={3}/>
          <this.FormInput name="password" label="Password" type="password" sm={6} md={4} lg={3}/>
        </Row>
        <Row>
          <Col>
            <Button color="success">Login</Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Login;
