import React, { Component } from 'react';
import moment from 'moment';
import { Button, Spinner, Badge, Form, Row, Col,
  Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { toast } from 'react-toastify';

import api from './API.js';
import { handleInputChange, FormInput, reactStateToDoc } from './Form.js';
import auth from './Auth/Auth.js';

export default class EligibilityModal extends (Component) {
  defaultState() {
    return {
      _loading: true,
      _validation: {},
      list: null,
    };
  }

  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.handleInputChange = handleInputChange.bind(this);
    this.FormInput = FormInput.bind(this);
    this.reactStateToDoc = reactStateToDoc.bind(this);
  }

  async check(event) {
    event && event.preventDefault(); // prevent page reload when submitted with Enter

    this.setState({_validation: {}});
    this.setState(this.defaultState());

    const filter = [...this.props.people || []];
    if (this.state.name || this.state.dateOfBirth) {
      filter.push({
        name: this.state.name,
        dateOfBirth: this.state.dateOfBirth,
        isFilter: true,
      });
    }

    if (filter.length > 0) {
      try {
        var uri = `checkEligibility`;
        if (this.props.applicationId) {
          uri += `/${this.props.applicationId}`;
        }
        const data = await api.post(uri, {people: filter});
        this.setState({
          ...data,
        });
      }
      catch (err) {
        toast.error('Something went wrong: ' + err, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
    this.setState({
      _loading: false,
    });
  }

  Badge(props) {
    const badgeTypes = {
      primary: {color: 'primary', label: 'primary'},
      secondary: {color: 'secondary', label: 'secondary'},
      dobMatch: {color: 'warning', label: 'D.O.B. match'},
      nameMatch: {color: 'warning', label: 'name match'},
    };
    const badgeType = badgeTypes[props.type] ||
      {color: 'info', label: props.type}; // a special "note" badge

    return <Badge color={badgeType.color}>{badgeType.label}</Badge>;
  }

  renderHistory() {
    if (this.state._loading) {
      return <Spinner/>;
    }

    if (!this.state.list) {
      return (
        <p>{"Please fill the date of birth or name to check eligibility."}</p>
      );
    }
    else if (!this.state.list.length) {
      return (
        <p>{"There are no previous applications for this client. Client is most likely eligible."}</p>
      );
    }
    return (
      <React.Fragment>
        <p>{"Please review the client's history and confirm eligibility."}</p>

        {(this.state.list.length >= 50) &&
          <div className="text-danger">
            {"More than 50 records matched and are not shown. Please refine your query."}
          </div>
        }

        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-right">App. Date</th>
              <th className="text-right">Amount Granted</th>
              <th className="text-right">Notes</th>
            </tr>
            {this.state.list.map((doc, i) => (
              <tr key={`row_${i}`}>
                <td>
                  {auth.hasPermission("edit_applications_all_fields") ?
                    <a href={`/editApplication/${doc.id}`} target="_blank" rel="noopener noreferrer">
                      {doc.name}
                    </a>
                  :
                    doc.name
                  }
                  <br/>
                  (DOB: {moment(doc.dateOfBirth).format('DD/MM/YYYY')})
                </td>
                <td className="text-right">
                  {moment(doc.date).format('DD/MM/YYYY')}
                  <br/>
                  <Badge>{doc.status}</Badge>
                </td>
                <td className="text-right">
                  ${(doc.amountGranted || 0).toFixed(2)}
                </td>
                <td className="text-right">
                  {(doc.badges || []).map(badge => (
                    <this.Badge key={badge} type={badge}/>
                  ))}
                </td>
              </tr>
            ))}
          </thead>
        </Table>
      </React.Fragment>
    );
  }

  handleEnter(e) {
    if (e.key === "Enter") {
      this.check();
    }
  }

  renderFilter() {
    return (
      <Form style={{paddingLeft: 15, maxWidth: '50em'}} onSubmit={this.check.bind(this)}>
        {this.props.people && (this.props.people.length>0) &&
          <React.Fragment>
            <h6>Current filter:</h6>
            {this.props.people.map((person, i) => (
              <Row key={`person_${i}`}>
                <Col xs={12} lg={6}>
                  <div>{person.name}</div>
                </Col>
                <Col xs={8} lg={4}>
                  <div>{person.dateOfBirth && moment(person.dateOfBirth).format('DD/MM/YYYY')}</div>
                </Col>
              </Row>
            ))}
            <br/>
          </React.Fragment>
        }
        <Row>
          <this.FormInput name="name" label="Name" xs={12} lg={(this.props.mode === "checkOnly") ? 6 : 10}
            validation={this.state._validation}
            onKeyPress={this.handleEnter.bind(this)}/>

          {(this.props.mode === "checkOnly") &&
            <this.FormInput name="dateOfBirth" label="D.O.B." xs={8} lg={4} type="date"
              onKeyPress={this.handleEnter.bind(this)}/>
          }
          <Col xs={4} lg={2} className="vh100">
            {this.state._blocking ? <Spinner color="primary" /> : (
              <Button color="success" className="bottom" type="submit"
                onKeyPress={this.handleEnter.bind(this)}>
                Find
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    return (
      <Modal size="xl"
        isOpen={this.props.isOpen}
        onOpened={() => {
          this.setState({...this.defaultState(), name: null, dateOfBirth: null}); // reset the filter form
          setTimeout(this.check.bind(this), 1); // setState doesn't change this.state immediately
        }}
      >
        <ModalHeader>Eligibility Check</ModalHeader>
        <ModalBody>
          {this.renderFilter()}
          {this.renderHistory()}
        </ModalBody>
        <ModalFooter>
          {this.props.mode === "checkOnly" ?
            <Button color="primary" onClick={() => this.props.onClose()}>Close</Button>
          :
            <React.Fragment>
              <Button color="success" onClick={() => this.props.onClose(true)}>Eligible</Button>
              <Button color="danger" onClick={() => this.props.onClose(false)}>Not Eligible</Button>
              <Button color="info" onClick={() => this.props.onClose()}>Decide Later</Button>
            </React.Fragment>
          }
        </ModalFooter>
      </Modal>
    );
  }
};
