import api, {ValidationException} from '../API.js';

function decodeToken(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

class Auth {
  constructor() {
    if (this.isAuthenticated()) {
      this.refreshToken();
    }
    setInterval(this._refreshAuthStatus.bind(this), 60000);
  }

  _refreshAuthStatus() {
    if (this.isAuthenticated()) {
      let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      var now = new Date().getTime();
      if ((expiresAt - now) < 6 * 60 * 60 * 1000) {
        // refresh token 6 hours before it expires
        this.refreshToken();
      }
    }
    else {
      if (window.location.pathname !== '/login') {
        window.location.replace('/login');
      }
    }
  }

  _handleToken(response) {
    var tokenData = decodeToken(response.token);

    let expiresAt = JSON.stringify(new Date(tokenData.exp * 1000).getTime());
    localStorage.setItem('id_token', response.token);
    localStorage.setItem('id_token_data', JSON.stringify(tokenData));
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('username', tokenData.username);
    localStorage.setItem('is_staff', tokenData.is_staff);
    localStorage.setItem('permissions', JSON.stringify(tokenData.permissions));
  }

  hasPermission(perm) {
    const perms = JSON.parse(localStorage.getItem('permissions'));
    return perms.indexOf(perm) >= 0;
  }

  async login(data) {
    // will throw ValidationException if login failed
    const response = await api.auth("api-token-auth", data);
    this._handleToken(response);
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_data');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('username');
    localStorage.removeItem('is_staff');
    window.location.replace('/login');
  }

  async refreshToken() {
    const data = {token: localStorage.getItem('id_token')};
    console.log(data);

    try {
      const response = await api.auth("api-token-refresh", data);
      this._handleToken(response);
    } catch (err) {
      if (err instanceof ValidationException) {
        // HTTP 400
        this.logout();
      }
    }
  }

  isAuthenticated() {
    // Check whether the current time is past the access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    var now = new Date().getTime();
    return now < expiresAt;
  }

  isAdmin() {
    try {
      return JSON.parse(localStorage.getItem('is_staff'));
    } catch(e) {
      return false;
    }
  }

  getTokenData() {
    return JSON.parse(localStorage.getItem('id_token_data')) || {};
  }

  getAgencyId() {
    return this.getTokenData().agency;
  }

  getToken() {
    return localStorage.getItem('id_token');
  }

  getUsername() {
    return localStorage.getItem('username');
  }
}

export default new Auth();
