import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { toast } from 'react-toastify';

import api from './API.js';

export default function(props) {
  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => { return {
    url: `${api.API_URL}/attachments/`,
    headers: api.headers(),
  } };

  const handleChangeStatus = (meta, status, allFiles) => {
    switch (status) {
      case "done":
        console.log(meta);
        // file finished uploading
        const attId = JSON.parse(meta.xhr.response).id;
        props.onUpload(attId, meta.meta.name);
        meta.remove();
        break;
      case "error_upload":
        console.log(meta);
        toast.error('Something went wrong: ' + meta.xhr.statusText, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
        });
        meta.remove();
        break;
      default:
    }
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxSizeBytes={5*1024*1024}
      inputWithFilesContent="Add More Files"
      //PreviewComponent={null}
      canRemove={false}
    />
  )
};
