import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import moment from 'moment';

import api from './API.js';
import { handleInputChange, FormInput } from './Form.js';
import auth from './Auth/Auth.js';

export default class ListApplications extends (Component) {
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      // preset last month for the reports
      dateFrom: moment().subtract(1, 'month').startOf('month'),
      dateTo: moment().subtract(1, 'month').endOf('month'),
    };
    this.handleInputChange = handleInputChange.bind(this);
    this.FormInput = FormInput.bind(this);
  }

  async componentDidMount() {
    const agencies = await api.get('agencies');
    this.setState({agencies: agencies});
  }

  backendLink(uri) {
    return `${process.env.REACT_APP_BACKEND_URL}/${uri}/?jwt=${auth.getToken()}`;
  }

  render() {
    const dateFrom = moment(this.state.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(this.state.dateTo).format('YYYY-MM-DD');
    return (
      <Container fluid>
        <Row>
          <this.FormInput name="dateFrom" label="Date From" xs={6} lg={3} type="date"/>
          <this.FormInput name="dateTo" label="Date To" xs={6} lg={3} type="date"/>
        </Row>

        {moment(this.state.dateFrom).isValid() && moment(this.state.dateTo).isValid() && (
          auth.hasPermission("download_csv_report") ?
            <React.Fragment>
              <a href={this.backendLink(`reports/csv/${dateFrom}/${dateTo}`)}>
                {"Download CSV (all data in defined date range)"}
              </a>
              <br/><br/>

              <h5>Download PDF report for agency:</h5>
              {(this.state.agencies || []).map(ag => (
                <React.Fragment key={ag.id}>
                  <a href={this.backendLink(`reports/agency/pdf/${ag.id}/${dateFrom}/${dateTo}`)}>
                    {ag.name}
                  </a>
                  <br/>
                </React.Fragment>
              ))}
            </React.Fragment>
          :
            auth.hasPermission("agency_report") && (
              <a href={this.backendLink(`reports/agency/pdf/${auth.getAgencyId()}/${dateFrom}/${dateTo}`)}>
                {"Download PDF Report"}
              </a>
            )
        )}
      </Container>
    );
  }
};
