import React, { Component } from 'react';
import { NavLink } from 'reactstrap';

import EligibilityModal from './EligibilityModal.js';

export default class EligibilityCheckerNavItem extends (Component) {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <NavLink href="#" onClick={() => this.setState({_eligibilityModalOpen: true})}>
        Check Eligibility
        <EligibilityModal
          isOpen={this.state._eligibilityModalOpen}
          mode="checkOnly"
          onClose={() => this.setState({_eligibilityModalOpen: false})}
        />
      </NavLink>
    );
  }
};
