import axios from 'axios';


export class ValidationException extends Error {
  constructor(data) {
    super("Validation Failed");
    this.data = data;
  }
}

class API {
  API_URL = `${process.env.REACT_APP_BACKEND_URL}/api`;

  headers() {
    const token = localStorage.getItem('id_token');
    return {"Authorization" : `jwt ${token}`}
  }

  async get(name, params={}) {
    const options = {
      method: "GET",
      params: params,
      headers: this.headers(),
      url: `${this.API_URL}/${name}/`,
    };
    const data = await axios(options);
    return data.data;
  }

  validateStatus(status) {
    return (status >= 200 && status < 300) // default
           || (status === 400); // validation failed
  }

  async _exec_save(url, method, data) {
    const options = {
      method: method,
      url: url,
      headers: {
        'content-type': 'application/json',
        ...this.headers(),
      },
      data: data,
      validateStatus: this.validateStatus,
    };
    const result = await axios(options);
    if (result.status === 400) {
      throw new ValidationException(result.data);
    }
    return result.data;
  }

  async post(name, data) {
    return this._exec_save(`${this.API_URL}/${name}/`, "POST", data);
  }

  async put(name, data) {
    return this._exec_save(`${this.API_URL}/${name}/`, "PUT", data);
  }
  async auth(name, data) {
    return this._exec_save(`${process.env.REACT_APP_BACKEND_URL}/auth/${name}/`, "POST", data);
  }
};

export default new API();
