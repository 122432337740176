import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap'
import { Badge, Button, Card, CardHeader, CardBody, CardText, CardTitle, Col, Container, Form, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import PaginationComponent from "react-reactstrap-pagination";

import api from './API.js';
import { handleInputChange, FormInput } from './Form.js';

const PAGE_SIZE = 50;

export default class ListApplications extends (Component) {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      statuses: {},
      recordCount: 0,
      activePage: 1,
      _loading: true,
    };
    this.handleInputChange = handleInputChange.bind(this);
    this.FormInput = FormInput.bind(this);
  }

  async loadList(event, pageNumber) {
    event && event.preventDefault(); // prevent page reload on filter form submit

    this.setState({
      _loading: true,
    });

    const filter = {limit: PAGE_SIZE};

    if (this.state.search) {
      filter.search = this.state.search;
    }
    if (this.state.submitted__gte) {
      filter.submitted__gte = `${this.state.submitted__gte}T00:00:00`;
    }
    if (this.state.submitted__lte) {
      filter.submitted__lte = `${this.state.submitted__lte}T23:59:59`;
    }
    if (pageNumber) {
      filter.offset = (pageNumber-1) * PAGE_SIZE;
    }

    const data = await api.get("applications", filter);
    this.setState({
      data: data.results,
      recordCount: data.count,
      activePage: pageNumber || 1,
      _loading: false,
    });
  }

  paginate(page) {
    this.loadList(null, page);
  }

  async loadMasterData() {
    const statuses = await api.get('statuses');

    const convertArrayToObject = (array, key) => {
      const initialValue = {};
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item,
        };
      }, initialValue);
    };
    this.setState({statuses: convertArrayToObject(statuses, 'name')});
  }

  statusColour(name) {
    return this.state.statuses && this.state.statuses[name] &&
      this.state.statuses[name].colour;
  }

  componentDidMount() {
    // these will run in parallel
    this.loadMasterData();
    this.loadList();
  }

  handleEnter(e) {
    if (e.key === "Enter") {
      this.loadList();
    }
  }

  render() {
    return (
      <Container fluid>
        <Form onSubmit={this.loadList.bind(this)}>
          <Row>
            <this.FormInput name="submitted__gte" label="Date From" xs={6} lg={3} type="date"
              onKeyPress={this.handleEnter.bind(this)}/>
            <this.FormInput name="submitted__lte" label="Date To" xs={6} lg={3} type="date"
              onKeyPress={this.handleEnter.bind(this)}/>

            <this.FormInput name="search" label="Name" xs={8} lg={3}
              onKeyPress={this.handleEnter.bind(this)}/>
            <Col xs={4} lg={1}>
              {this.state._loading ? <Spinner color="primary" /> : (
                <Button color="success" className="bottom" type="submit"
                  onKeyPress={this.handleEnter.bind(this)}>
                  Filter
                </Button>
              )}
            </Col>
          </Row>
        </Form>

        <Row className="mt-2">
          {this.state.data.map((doc, i) => (
            <Col key={`app_${i}`} xs="6" sm="4" lg="3" xl="2">
              <LinkContainer to={`/editApplication/${doc.id}`}>
                <Card className={"mb-2 pointer"}>
                  <CardHeader className="limitHeader" title={(doc.people_details || []).map(person => person.name).join("\n")}>
                    {(doc.people_details || []).map(person => (person.is_primary_client && person.name))}
                  </CardHeader>
                  <CardBody className="px-1">
                    <CardTitle>
                      {moment(doc.submitted).format('DD/MM/YYYY')}<br/>
                    </CardTitle>
                    <CardText>
                      {doc.agencyName}{doc.agencyName && <br/>}
                      <Badge style={{backgroundColor: this.statusColour(doc.status)}}>
                        {doc.status}
                      </Badge>
                    </CardText>
                  </CardBody>
                </Card>
              </LinkContainer>
            </Col>
          ))}
        </Row>

        <PaginationComponent
          totalItems={this.state.recordCount}
          pageSize={PAGE_SIZE}
          activePage={this.state.activePage}
          onSelect={this.paginate.bind(this)}
        />
      </Container>
    );
  }
};
