import React from 'react';
import { Col, FormGroup, Label, Input, InputGroup } from 'reactstrap';
import moment from 'moment';


export function extractInputValue(event) {
  const target = event.target;
  var value = target.value;
  if (target.type === 'checkbox') {
    // a simple true/false checkbox
    value = target.checked;
  }
  return value;
}

export function handleInputChange(event) {
  const target = event.target;
  const name = target.name;
  this.setState({
    [name]: extractInputValue(event)
  });
}

export function FormInput(props) {
  const state = props.state || this.state;
  const disabled = this.state._form_disabled || props.disabled;
  const checkbox = (props.type === "checkbox");
  var value = state[props.name] || "";
  switch(props.type) {
    case "checkbox":
      value = "true";
      break;
    case "date":
      value = moment(value).format("YYYY-MM-DD");
      break;
    default:
  }

  const validation = (props.validation || this.state._validation || {})[props.name];

  const options = [...props.options || []];
  if ((props.type === "select") && value && value.length && !options.find(e => (props.useIdAsValue ? String(e.id) : e.name) === value)) {
    options.unshift({id: value, name: value});
  }

  var inputComponent = null;
  switch (props.type) {
    case "select":
      inputComponent = (
        <Input type={props.type} name={props.name} id={props.name}
          className={validation && "is-invalid"}
          disabled={disabled}
          value={value}
          placeholder={props.placeholder}
          onChange={props.onChange || this.handleInputChange}
        >
          <option value="">---</option>
          {(options || []).map(o => (
            <option key={o.id} value={props.useIdAsValue ? o.id : o.name}>{o.name}</option>
          ))}
        </Input>
      );
      break;
    case "custom":
      break;
    default:
      inputComponent = (
        <Input type={props.type || "text"} name={props.name} id={props.name}
          className={validation && "is-invalid"}
          disabled={disabled}
          value={value}
          checked={props.checked}
          placeholder={props.placeholder}
          onKeyPress={props.onKeyPress}
          onChange={props.onChange || this.handleInputChange}/>
      );
  }

  const validationComponent = validation && (
    <div className="invalid-feedback">
      {validation}
    </div>
  );

  return (
    <Col xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
      <FormGroup check={(props.type === "checkbox")}>
        <Label for={props.name}>
          {checkbox && inputComponent}
          {props.label}
          {checkbox && validationComponent}
        </Label>
        <InputGroup>
          {!checkbox && inputComponent}
          {props.children}
          {!checkbox && validationComponent}
        </InputGroup>
      </FormGroup>
    </Col>
  );
};

export function reactStateToDoc() {
  /** remove all properties starting with _ **/
  var doc = Object.assign({}, this.state);
  for (var k in doc) {
    if (k.startsWith("_") && (k !== "_id")) {
      delete doc[k];
    }
  }
  return doc
}
