import React, { Component } from 'react';
import moment from 'moment';
import { Button, Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { toast } from 'react-toastify';

import api from './API.js';

export default class EligibilityModal extends (Component) {
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      history: null,
    };
  }

  async load(event) {
    this.setState({
      _loading: true,
      history: null,
    });
    try {
      var uri = `applications/history/${this.props.applicationId}`;
      const data = await api.get(uri);
      this.setState({
        ...data,
      });
    }
    catch (err) {
      toast.error('Something went wrong: ' + err, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }

    this.setState({
      _loading: false,
    });
  }

  render() {
    const history = (this.state.history || []).concat([{
      user: this.props.createdBy,
      ts: this.props.submitted,
      changes: 'Application created.'
    }]);

    return (
      <Modal size="xl"
        isOpen={this.props.isOpen}
        onOpened={this.load.bind(this)}
      >
        <ModalHeader>Audit Log History</ModalHeader>
        <ModalBody>
          {this.state._loading ? <Spinner color="primary" /> :
            <Table>
              <thead>
                <tr>
                  <th className="text-right">Date/Time</th>
                  <th>User</th>
                  <th>Changes</th>
                </tr>
                {history.map((doc, i) => (
                  <tr key={`row_${i}`}>
                    <td className="text-right">
                      {moment(doc.ts).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    <td>
                      {doc.user}
                    </td>
                    <td>
                      <div className="nl2br">{doc.changes}</div>
                    </td>
                  </tr>
                ))}
              </thead>
            </Table>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.props.onClose()}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
};
