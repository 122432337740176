import React, { Component } from 'react';
import { Button, Col, Row, Form, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { handleInputChange, FormInput, reactStateToDoc } from '../Form.js';
import api, {ValidationException} from '../API.js';


export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: '',
      new_password: '',
      new_password_verify: '',
      _validation: {},
      _loading: false,
    };
    this.handleInputChange = handleInputChange.bind(this);
    this.FormInput = FormInput.bind(this);
    this.reactStateToDoc = reactStateToDoc.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({_validation: {}, _loading: true});
    try {
      var data = this.reactStateToDoc(this.state);
      await api.put(`changePassword`, data);
      this.setState({_loading: false});

      toast.success('Password changed.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      this.props.history.push("/");
    }
    catch (err) {
      this.setState({_loading: false});
      if (err instanceof ValidationException) {
        this.setState({_validation: {
          ...err.data,
        }});
      }
      else {
        toast.error('Something went wrong: ' + err, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <Row>
          <this.FormInput name="old_password" label="Current Password" type="password" md={4}/>
          <this.FormInput name="new_password" label="New Password" type="password" md={4}/>
          <this.FormInput name="new_password_verify" label="New Password Confirm" type="password" md={4}/>
        </Row>
        <Row>
          <Col>
            {this.state._loading ? <Spinner color="primary" /> : (
              <Button color="primary">Confirm</Button>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}
