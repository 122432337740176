import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { Button, Col, Row, Spinner, Form, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { MdDeleteForever } from "react-icons/md";
import confirm from "reactstrap-confirm";
import moment from 'moment';

import api, {ValidationException} from './API.js';
import EligibilityModal from './EligibilityModal.js';
import HistoryModal from './HistoryModal.js';
import { handleInputChange, FormInput, reactStateToDoc, extractInputValue } from './Form.js';
import FileUpload from './FileUpload.js';
import auth from './Auth/Auth.js';

class EditApplication extends (Component) {
  constructor(props) {
    super(props);
    this.state = {
      _loading: !!this.props.id,
      _validation: {},
      _disclaimer: !!this.props.id, // pre-check the disclaimer for existing applications
      people_details: [{}], // add at least the main client
      agency: auth.getAgencyId(),
    };

    this.handleInputChange = handleInputChange.bind(this);
    this.FormInput = FormInput.bind(this);
    this.reactStateToDoc = reactStateToDoc.bind(this);
  }

  handleStatus() {
    const defaultStatus = this.state._statuses.find(e => e.default) || this.state._statuses[0];
    if (this.props.id) {
      // we've loaded existing record
      if (!auth.hasPermission("edit_applications_all_fields")) {
        // check if I'm allowed to edit this application
        const currentStatus = this.state._statuses.find(e => e.name === this.state.status);
        if (currentStatus && !currentStatus.allow_agency_edit) {
          this.setState({_form_disabled: true});
        }
        else {
          // set status to the default one
          this.setState({status: defaultStatus.name});
        }
      }
    }
    else {
      // this is a new application, prepare the default status
      this.setState({status: defaultStatus.name});
    }
    this.setState({_loading: false,});
  }

  async loadDoc() {
    if (this.props.id) {
      const data = await api.get(`applications/${this.props.id}`);
      this.setState({
        ...data,
      });
    }
  }

  async loadList(name) {
    const data = await api.get(name);
    this.setState({[`_${name}`]: data});
  }

  async componentDidMount() {
    try {
      await Promise.all([
        this.loadDoc(),
        this.loadList('suburbs'),
        this.loadList('budgetingServices'),
        this.loadList('electricityProviders'),
        this.loadList('incomeTypes'),
        this.loadList('ethnicities'),
        this.loadList('housing'),
        this.loadList('insulation'),
        this.loadList('reasons'),
        this.loadList('specialNotes'),
        this.loadList('agencies'),
        this.loadList('statuses')
      ]);
      // run async to make sure this.state is updated
      setTimeout(this.handleStatus.bind(this), 0);
    } catch (err) {
      toast.error('Something went wrong: ' + err, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({_blocking: true});

    try {
      if (!this.state._disclaimer) {
        throw new ValidationException({_disclaimer: "This field is mandatory."});
      }

      this.state.people_details[0].is_primary_client = true;

      const data = {
        attachments: [], // default, required
        ...this.reactStateToDoc(),
        id: this.props.id || uuid(),
      };
      delete data.flatmates_details;

      if (this.props.id) {
        await api.put(`applications/${this.props.id}`, data);
      }
      else {
        await api.post("applications", data);
      }

      toast.success('Application form saved successfully.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });

      this.props.history.push("/");
    }
    catch (err) {
      if (err instanceof ValidationException) {
        this.setState({_validation: {
          ...err.data,
        }});
      }
      else {
        toast.error('Something went wrong: ' + err, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
    this.setState({_blocking: false});
  }

  eligibilityChecked(state) {
    this.setState({
      eligible: state,
      _eligibilityModalOpen: false,
    });
  }

  onFileUpload(id, name, content_type) {
    const atts = this.state.attachments || [];
    const atts_details = this.state.attachments_details || [];
    atts.push(id);
    atts_details.push({
      id: id,
      name: name
    });
    this.setState({
      attachments: atts,
      attachments_details: atts_details,
    });
  }

  async deleteAttachment(id) {
    const result = await confirm({
        message: "Are you sure you want to remove this document?",
        confirmText: "Remove",
        confirmColor: "danger",
        cancelText: "Keep it!",
        cancelColor: "secondary"
    });

    if (result) {
      this.setState({
        attachments: this.state.attachments.filter(val => val !== id), // this is for saving to db
        attachments_details: this.state.attachments_details.filter(val => val.id !== id),// this is for displaying details
      });
    }
  }

  handlePersonChange(i, name, event) {
    const person = this.state.people_details[i];
    person[name] = extractInputValue(event);
    this.setState({people_details: this.state.people_details});
  }

  render() {
    if (this.state._loading) {
      return <Spinner/>
    }
    const eligibilityButtonColors = {true: 'success', false: 'danger', null: 'warning', undefined: 'warning'};
    const pendingLabel = auth.hasPermission("check_eligibility") ? 'Check Eligibility' : 'Eligibility Check Pending';
    const eligibilityButtonLabels = {true: 'Eligible', false: 'Not Eligible', null: pendingLabel, undefined: pendingLabel};

    const allowAllFields = auth.hasPermission("edit_applications_all_fields");

    return (
      <React.Fragment>
        <EligibilityModal
          clientId={this.state.client_id}
          applicationId={this.state.id}
          people={this.state.people_details}
          isOpen={this.state._eligibilityModalOpen}
          onClose={this.eligibilityChecked.bind(this)}
        />
        <Form onSubmit={this.handleSubmit.bind(this)} style={{paddingLeft: 15, maxWidth: '50em'}}>

          {!this.props.id && <h1>New Application</h1>}

          {(this.state.people_details || [{}]).map((flatmate, i) => (
            <Row key={i}>
              <this.FormInput state={flatmate} name="name" md={6} label={!i && "Name"}
                onChange={evt => this.handlePersonChange(i, 'name', evt)}
                validation={(this.state._validation.people_details || [])[i]}/>
              <this.FormInput state={flatmate} name="dateOfBirth" md={4} type="date" label={!i && "D.O.B"}
                onChange={evt => this.handlePersonChange(i, 'dateOfBirth', evt)}
                validation={(this.state._validation.people_details || [])[i]}/>
              {(i > 0) &&
                <this.FormInput name="_button" md={2} type="custom">
                  <Button
                    color="danger"
                    disabled={this.state._form_disabled}
                    onClick={() => {
                      this.state.people_details.splice(i, 1);
                      this.forceUpdate();
                    }}>
                    Remove
                  </Button>
                </this.FormInput>
              }
            </Row>
          ))}
          <Row className="mb-3">
            <Col xs={12}>
              <Button
                disabled={this.state._form_disabled}
                onClick={() => {
                  this.state.people_details.push({});
                  this.forceUpdate();
                }}>
                Add More People
              </Button>
              <Button
                className="ml-3"
                color={eligibilityButtonColors[this.state.eligible]}
                disabled={
                  this.state._form_disabled ||
                  !this.state.people_details[0].dateOfBirth ||
                  !auth.hasPermission("check_eligibility")
                }
                onClick={() => this.setState({_eligibilityModalOpen: true})}>
                {eligibilityButtonLabels[this.state.eligible]}
              </Button>
            </Col>
          </Row>

          <Row>
            <this.FormInput name="address" label="Address"/>
            <this.FormInput name="addressSuburb" label="Suburb" type="select" options={this.state._suburbs}/>
          </Row>
          <Row>
            <this.FormInput name="ethnicity" label="Ethnicity" type="select" options={this.state._ethnicities}/>
            <this.FormInput name="housing" label="Housing" type="select" options={this.state._housing}/>
            <this.FormInput name="insulation" label="Insulation" type="select" options={this.state._insulation}/>
          </Row>

          <Row>
            <this.FormInput name="incomeType" label="Income Type" type="select" options={this.state._incomeTypes}/>
            <this.FormInput name="incomeAnnual" label="Annual Income"/>
          </Row>
          <Row>
            <this.FormInput name="underBudgeting" label="Under Budgeting" type="checkbox" checked={this.state.underBudgeting}/>
            {this.state.underBudgeting &&
              <this.FormInput name="budgetingService" label="Service" type="select" options={this.state._budgetingServices}/>}
            {this.state.underBudgeting &&
              <this.FormInput name="budgetingWorkerName" label="Worker's Name"/>}
          </Row>

          <Row>
            <this.FormInput name="electricityProvider" label="Electricity Provider" type="select" options={this.state._electricityProviders}/>
            <this.FormInput name="electricityProviderCustomerNo" label="Customer No."/>
            <this.FormInput name="amountRequested" label="Amount Requested"/>
          </Row>
          <Row>
            <this.FormInput name="reason" label="Reason for Request" type="select" options={this.state._reasons}/>
            <this.FormInput name="specialNote" label="Note" type="select" options={this.state._specialNotes}/>
          </Row>
          <Row>
            <this.FormInput name="documents" label="Evidence / Documents" type="custom">
              <ListGroup>
                {(this.state.attachments_details || []).map((att) => (
                  <ListGroupItem key={att.id}>
                    <a href={`${api.API_URL}/attachments/${att.id}?jwt=${auth.getToken()}`} target="_blank" rel="noopener noreferrer">
                      {att.name}
                    </a>
                    <Button
                      color="link"
                      disabled={this.state._form_disabled}
                      onClick={() => this.deleteAttachment(att.id)}
                    >
                      <MdDeleteForever className="ml-2" color="red"/>
                    </Button>
                  </ListGroupItem>
                ))}
              </ListGroup>
              {!this.state._form_disabled &&
                <FileUpload
                  onUpload={this.onFileUpload.bind(this)}
                />
              }
            </this.FormInput>
          </Row>
          <Row>
            <Col><h5>Agency</h5></Col>
          </Row>
          <Row>
            <this.FormInput name="agency" label="Agency" type="select"
              options={this.state._agencies} useIdAsValue
              disabled={!!auth.getAgencyId()}
            />
            <this.FormInput name="timeSpent" label="Time Spent"/>
          </Row>
          <Row>
            <this.FormInput name="status" label="Status" type="custom">
              <Input type="select" name="status" id="status"
                className={this.state._validation.status && "is-invalid"}
                value={this.state.status}
                onChange={this.handleInputChange}
              >
                {(this.state._statuses || []).map(o => {
                  if (allowAllFields || o.allow_agency_use) {
                    return <option key={o.id} value={o.name}>{o.name}</option>;
                  }
                  return null;
                })}
              </Input>
            </this.FormInput>
            <this.FormInput name="amountGranted" label="Amount Granted" disabled={!allowAllFields}/>
          </Row>

          {this.props.id &&
            <Row>
              <this.FormInput name="submitted" label="Submitted On" type="date" disabled/>
              <this.FormInput name="created_by" label="Submitted By" type="text" disabled/>
              <Col>
                <HistoryModal
                  applicationId={this.state.id}
                  isOpen={this.state._historyModalOpen}
                  createdBy={this.state.created_by}
                  submitted={this.state.submitted}
                  onClose={() => this.setState({_historyModalOpen: false})}
                />
                <Button
                  className="bottom"
                  onClick={() => this.setState({_historyModalOpen: true})}
                >
                  Show History
                </Button>
              </Col>
            </Row>
          }

          <Row>
            <this.FormInput name="_disclaimer" type="checkbox" checked={this.state._disclaimer}
              label="The distributing agency has explained to client that their information is to be inspected and recorded by the administering agency and client is aware their file may be shared with representative of the contract provider (DCC) for the purposes of a quality review of this service."
            />
          </Row>

          {this.state.comments_details && this.state.comments_details.length > 0 && <h5>Comments</h5>}
          {(this.state.comments_details || []).map(c => (
            <Row key={c.id}>
              <Col xs={6} md={3}>
                <div>{moment(c.timestamp).format('DD/MM/YYYY hh:mm')}</div>
              </Col>
              <Col xs={6} md={3}>
                <div>{c.author_name}</div>
              </Col>
              <Col xs={12} md={6}>
                <p className="nl2br">{c.content}</p>
              </Col>
            </Row>
          ))}

          <Row>
            <this.FormInput name="comment" type="textarea" label="Add Comment"/>
          </Row>
          <Row>
            <Col>
              {!this.state._form_disabled &&
                (this.state._blocking ? <Spinner color="primary" /> : <Button color="primary" size="lg">Submit</Button>)
              }
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
};

export default withRouter(EditApplication);
